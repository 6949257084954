/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Tooltip, Pagination, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Spinner, Divider } from '@nextui-org/react';
import { IconEdit, IconEye, IconMapPin, IconSearch, IconTrash, IconPhone, IconHash, IconPlus, IconUserDollar, IconBuildingStore, IconUserStar, IconClock24, IconClockDollar } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { Constants, formatAmount } from '../../utils';
import { StoresService } from '../../services';
import ModalEdit from './edit-store';
import ModalCreate from './create-store';

const google = window.google;

const defaultLatLng = {
	latitude: 29.7604315123649,
	longitude: -95.36981707226198,
};

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
  CREATE: 2,
  EDIT: 3,
  DELETE: 4,
}

const Stores = () => {

  const [selectedStore, setSelectedStore] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { stores, deleteStore, filterBy, goToPage, isLoading, pagination, reload } = useFetchTable();

  const onSelectItem = (store, action) => {
    setSelectedStore(store);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedStore(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="3xl"
        isOpen={(!!selectedStore && modalAction !== MODAL_ACTION.DELETE) || modalAction === MODAL_ACTION.CREATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.VIEW) return (
              <ModalView
                store={selectedStore}
                onClose={onClose}
                onEdit={() => setModalAction(MODAL_ACTION.EDIT)}
              />
            );
            if (modalAction === MODAL_ACTION.CREATE) return (
              <ModalCreate onClose={onClose} onSuccess={() => closeModal(true)} />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <ModalEdit
                store={selectedStore}
                onClose={onClose}
                onSuccess={() => closeModal(true)}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Modal
        size="sm"
        isOpen={selectedStore && modalAction === MODAL_ACTION.DELETE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalDelete
              onClose={onClose}
              onDelete={()=> {
                deleteStore(selectedStore?.id);
                closeModal(true);
              }}
            />
          )}
        </ModalContent>
      </Modal>

      <section className="mb-4 flex gap-4">
        <Input
          classNames={{
            base: 'w-full sm:max-w-xs',
            inputWrapper: 'border-1 h-10 bg-white',
          }}
          label="Nombre"
          labelPlacement="outside"
          placeholder="Buscar por Nombre/Número de Tienda"
          startContent={<IconSearch />}
          variant="bordered"
          onValueChange={v => filterBy(v, 'search')}
        />
      </section>

      <Table
        aria-label="Tiendas registradas"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primaryDark">Tiendas registradas</h3>
            <Button
              color="primary"
              className="pl-2"
              startContent={<IconPlus color="white" />}
              onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
            >
              Nuevo
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Nombre</TableColumn>
          <TableColumn>Número</TableColumn>
          <TableColumn>Teléfono</TableColumn>
          <TableColumn>Dirección</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={stores}>
          {(store) => (
            <TableRow key={store.id}>
              <TableCell>{ store?.name }</TableCell>
              <TableCell>{ store?.number }</TableCell>
              <TableCell>{ store?.phone }</TableCell>
              <TableCell className="flex items-center">
                <IconMapPin className="self-center mr-2 cursor-pointer" onClick={() => onSelectItem(store, MODAL_ACTION.VIEW)} />
                { store?.address }
              </TableCell>
              <TableCell align="right" className="">
                <div className="relative flex justify-end items-center gap-2">
                  <Tooltip content="Ver detalles">
                    <IconEye onClick={() => onSelectItem(store, MODAL_ACTION.VIEW)} />
                  </Tooltip>
                  <Tooltip content="Editar">
                    <IconEdit onClick={() => onSelectItem(store, MODAL_ACTION.EDIT)} />
                  </Tooltip>
                  <Tooltip color="danger" content="Eliminar">
                    <IconTrash onClick={() => onSelectItem(store, MODAL_ACTION.DELETE)} />
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const ModalView = ({ store, onEdit, onClose }) => {
  const mapRef = useRef();

  const setMap = async () => {
    mapRef.current = new google.maps.Map(document.getElementById('store-location-map'), {
      zoom: 13,
      center: new google.maps.LatLng(
        store?.latitude ? Number(store.latitude) : defaultLatLng.latitude,
        store?.longitude ? Number(store.longitude) : defaultLatLng.longitude
      ),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });

    new google.maps.Marker({
      position: mapRef.current.getCenter(),
      map: mapRef.current,
      title: '',
      animation: google.maps.Animation.DROP,
      draggable: false,
    });
  }

  useEffect(() => {
    setMap();
  }, []);

  return (
    <div>
      <ModalHeader>{ store?.name }</ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full grid grid-cols-3 gap-4">
          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconHash size={20} />
              <p>Número</p>
            </div>
            <p className="text-sm">{ store?.number }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconPhone size={20} />
              <p>Teléfono</p>
            </div>
            <p className="text-sm">{ store?.phone }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconMapPin size={20} />
              <p>Dirección</p>
            </div>
            <p className="text-sm">{ store?.address }</p>
          </div>
        </div>

        <Divider />

        <div className="w-full grid grid-cols-3 gap-4">
          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconBuildingStore size={20} />
              <p>Valor tienda $ <small>(máximo)</small></p>
            </div>
            <p className="text-sm">{ formatAmount(store?.value_store) }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconUserDollar size={20} />
              <p>Valor limpiador $</p>
            </div>
            <p className="text-sm">{ formatAmount(store?.value_cleaner) }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconUserStar size={20} />
              <p>Valor supervisor $</p>
            </div>
            <p className="text-sm">{ formatAmount(store?.value_supervisor) }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconClock24 size={20} />
              <p>Valor día $ <small>(Valor tienda/30)</small></p>
            </div>
            <p className="text-sm">{ formatAmount(store?.value_day) }</p>
          </div>

          <div>
            <div className="flex text-sm items-center gap-2 text-primary">
              <IconClockDollar size={20} />
              <p>Valor hora $ <small>(Valor día/xx horas)</small></p>
            </div>
            <p className="text-sm">{ formatAmount(store?.value_hour) }</p>
          </div>
        </div>

        <Divider />

        <div id="store-location-map" className="w-full h-60" />
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" color="danger" onPress={onEdit}>Editar</Button>
        <Button variant="light" onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalDelete = ({ onClose, onDelete }) => {
  return (
    <>
      <ModalHeader className="flex flex-col gap-1">Confirmación</ModalHeader>
      <ModalBody>
        <p>¿Estás seguro de eliminar esta tienda?</p>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cancelar</Button>
        <Button color="primary" onPress={onDelete}>Aceptar</Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    since: null,
    until: null,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await StoresService.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await StoresService.destroy(id);
      toast.success('Tienda eliminada con éxito');

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    changePerPage,
    stores: data,
    deleteStore: deleteItem,
    filterBy,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
  }
}

export default Stores;
