/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { clone, formatAmount, fromPhotos } from '../../utils';
import { PayrollService } from '../../services';
import { CurrencyInput } from '../../components';

const MODAL_ACTION = {
  NONE: 0,
  EARNING: 1,
  PAYMENT: 2,
  SEND_RECEIPT: 3,
};

const banks = [
  { value: '1', label: 'Mercantil', },
];

const emitterBanks = [
  { value: '1', label: 'Banco Nacional de Crédito', },
];

const Calculation = () => {

  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, data, filterBy, reload } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedItem(order);
    setModalAction(action);
  }

  const closeModal = () => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
  }

  return (
    <>
      <Modal
        size="md"
        isOpen={modalAction === MODAL_ACTION.EARNING}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalEarning
              item={selectedItem}
              onClose={onClose}
              onSuccess={() => closeModal()}
            />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalAction === MODAL_ACTION.PAYMENT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalPayment
              item={selectedItem}
              onClose={onClose}
              onSuccess={() => closeModal()}
            />
          )}
        </ModalContent>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.SEND_RECEIPT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalSendReceipt
              item={selectedItem}
              onClose={onClose}
              onSuccess={() => closeModal()}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      {data.map((cleaner, cleanerIdx) => {
        const itemsLength = 3;
        return (
          <Table
            key={cleanerIdx}
            aria-label="Desempeño Semanal"
            classNames={{
              base: 'mb-4',
              wrapper: 'p-2',
              th: 'whitespace-pre-line',
              td: 'border-t-1 first:border-r-1 border-collapse',
              tr: 'border-b-1',
            }}
          >
            <TableHeader>
              <TableColumn className="w-48">Trabajador</TableColumn>
              <TableColumn>Tiendas{'\n'}asignadas</TableColumn>
              <TableColumn>Número de{'\n'}tienda</TableColumn>
              <TableColumn>Horas{'\n'}trabajadas</TableColumn>
              <TableColumn>Valor{'\n'}día $</TableColumn>
              <TableColumn>Tasa por horas{'\n'}extras $</TableColumn>
              <TableColumn>Salario{'\n'}Subtotal</TableColumn>
              <TableColumn>Otros{'\n'}ingresos $</TableColumn>
              <TableColumn>Salario Neto{'\n'}Total $</TableColumn>
              <TableColumn></TableColumn>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="w-48 bg-[#DEF6FF]" rowSpan={itemsLength}>
                  <div className="w-48 overflow-hidden">
                    <User
                      classNames={{ name: 'truncate', description: 'truncate' }}
                      avatarProps={{ showFallback: true, radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
                      name="Vicente Roo"
                      description="vroo@mail.com"
                    />
                  </div>
                </TableCell>
                <TableCell>UPS 248</TableCell>
                <TableCell>48</TableCell>
                <TableCell>100</TableCell>
                <TableCell>6,50</TableCell>
                <TableCell>36,00</TableCell>
                <TableCell>650,00</TableCell>
                <TableCell rowSpan={itemsLength} className="border-l-1">
                  <div className="flex items-center">
                    <p>100,00</p>
                  </div>
                </TableCell>
                <TableCell rowSpan={itemsLength}>
                  <div className="flex items-center">
                    <p>1.225,00</p>
                  </div>
                </TableCell>
                <TableCell rowSpan={itemsLength} align="right" className="w-36">
                  <div className="flex flex-col gap-2 w-36">
                    {!!cleanerIdx && (
                      <>
                        <Button
                          className="text-primary border-primary font-semibold"
                          variant="bordered"
                          size="sm"
                          onClick={() => onSelectItem(cleaner, MODAL_ACTION.EARNING)}
                        >
                          Registrar otro ingreso
                        </Button>
                        <Button
                          className="bg-[#5DAD4A] text-white font-semibold"
                          size="sm"
                          onClick={() => onSelectItem(cleaner, MODAL_ACTION.PAYMENT)}
                        >
                          Registrar pago
                        </Button>
                      </>
                    )}

                    {!cleanerIdx && (
                      <Button
                        className="text-white font-semibold"
                        color="primary"
                        size="sm"
                        onClick={() => onSelectItem(cleaner, MODAL_ACTION.SEND_RECEIPT)}
                      >
                        Enviar recibo
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell hidden></TableCell>
                <TableCell>UPS 248</TableCell>
                <TableCell>48</TableCell>
                <TableCell>100</TableCell>
                <TableCell>6,50</TableCell>
                <TableCell>36,00</TableCell>
                <TableCell>650,00</TableCell>
                <TableCell hidden></TableCell>
                <TableCell hidden></TableCell>
                <TableCell hidden></TableCell>
              </TableRow>
              <TableRow>
                <TableCell hidden></TableCell>
                <TableCell>UPS 248</TableCell>
                <TableCell>48</TableCell>
                <TableCell>100</TableCell>
                <TableCell>6,50</TableCell>
                <TableCell>36,00</TableCell>
                <TableCell>650,00</TableCell>
                <TableCell hidden></TableCell>
                <TableCell hidden></TableCell>
                <TableCell hidden></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      })}
    </>
  );
}

const ModalEarning = ({ item, onClose, onSuccess }) => {

  const [form, setForm] = useState({
    amount: '',
    comment: '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Otro Ingreso</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="flex flex-col gap-4">
            <CurrencyInput
              classNames={{ inputWrapper: 'border-1 !h-10 bg-white' }}
              label="Monto a asignar $"
              placeholder=" "
              value={form.amount}
              onValueChange={v => onChange(v, 'amount')}
            />
            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Motivo"
              labelPlacement="outside"
              placeholder="Escribir"
              variant="bordered"
              value={form.comment}
              onValueChange={v => onChange(v, 'comment')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onClose}>Guardar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalSendReceipt = ({ item, onClose, onSuccess }) => {
  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col"></ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full px-4">
          <User
            classNames={{
              name: 'truncate text-md font-semibold',
              description: 'truncate text-sm text-gray',
            }}
            avatarProps={{ showFallback: true, radius: 'full', src: item?.person?.photo ? fromPhotos(item.person.photo) : null }}
            name="Charlotte, NC"
            description="RFC; 998554699"
          />

          <div className="my-4 flex flex-1 justify-between text-sm">
            <div>
              <p className="font-semibold">Trabajador: Vicente Roo</p>
              <p>DNI: C-9087654-3</p>
            </div>
            <div className="text-right">
              <p className="font-semibold">Fecha emisión</p>
              <p>25-08-2024</p>
            </div>
          </div>

          <div className="grid [&>p:nth-child(even)]:text-right text-sm" style={{ gridTemplateColumns: '5fr 2fr' }}>
            <p className="font-semibold">Concepto</p>
            <p className="font-semibold">Monto $</p>
            <p>Devengado UPS 248</p>
            <p>{ formatAmount(650) }</p>
            <p>Devengado UPS 15</p>
            <p>{ formatAmount(300) }</p>
            <p>Devengado ULTA 33</p>
            <p>{ formatAmount(175) }</p>
          </div>
        </div>

        <Divider className="my-2" />

        <div className="w-full px-4">
          <div
            className="grid [&>p:nth-child(even)]:text-right text-sm font-semibold gap-4 mb-4"
            style={{ gridTemplateColumns: '5fr 2fr' }}
          >
            <p>Salario Bruto Devengado $</p>
            <p>{ formatAmount(1125) }</p>
            <p>Otros ingresos</p>
            <p>{ formatAmount(100) }</p>
          </div>

          <div
            className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex flex-1 justify-between items-center [&>p:nth-child(even)]:text-right text-sm font-bold text-primary gap-4 -mx-4"
          >
            <p>Total Salario Neto $</p>
            <p className="text-medium">{ formatAmount(1125) }</p>
          </div>

          <p className="my-4 text-sm text-center whitespace-pre-line">
            De no emitir reclamo, certifico haber recibido la cantidad{'\n'}indicada de salario neto, así como copia del presente recibo. 
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onClose}>Enviar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalPayment = ({ item, onClose, onSuccess }) => {

  const [form, setForm] = useState({
    receiverBankId: '',
    emitterBankId: '',
    date: '',
    reference: '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Pago</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex justify-between gap-4 flex-1">
            <div className="overflow-hidden">
              <User
                classNames={{
                  name: 'truncate text-md font-semibold',
                  description: 'truncate text-sm text-gray',
                }}
                avatarProps={{ showFallback: true, radius: 'full', src: item?.person?.photo ? fromPhotos(item.person.photo) : null }}
                name="Vicente Roo"
                description="vroo@mail.com"
              />
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-[#202C4E]">{ formatAmount(1225) }</p>
              <p className="text-[13px]">Monto Pagado $</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Select
              label="Banco destino"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.receiverBankId ? [form.receiverBankId]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'receiverBankId')}
            >
              {banks.map((el) => (
                <SelectItem key={el.value} value={el.value}>
                  { el.label }
                </SelectItem>
              ))}
            </Select>

            <Select
              label="Banco emisor"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.emitterBankId ? [form.emitterBankId]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'emitterBankId')}
            >
              {emitterBanks.map((el) => (
                <SelectItem key={el.value} value={el.value}>
                  { el.label }
                </SelectItem>
              ))}
            </Select>

            <Input
              type="date"
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
                input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
              }}
              label="Fecha de pago"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.date}
              onValueChange={v => onChange(v, 'date')}
            />

            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Referencia"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.reference}
              onValueChange={v => onChange(v, 'reference')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onClose}>Guardar</Button>
      </ModalFooter>
    </div>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    searchEmployee: '',
    searchStore: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Nombre del trabajador"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder=" "
        onClear={() => onChange('', 'searchEmployee')}
        variant="bordered"
        value={form.searchEmployee}
        onValueChange={v => onChange(v, 'searchEmployee')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[12rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Nombre de la tienda"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder=" "
        onClear={() => onChange('', 'searchStore')}
        variant="bordered"
        value={form.searchStore}
        onValueChange={v => onChange(v, 'searchStore')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        min={new Date().toISOString().split('T')[0]}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full lg:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        min={new Date().toISOString().split('T')[0]}
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: 20,
    searchEmployee: '',
    searchStore: '',
    since: '',
    until: '',
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: 20,
  };

  const [data, setData] = useState([{id: 1},{id: 2}]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.get(filters);
      const { data, ...rest } = response;
      setData(parseWeekData(data));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const parseWeekData = (data) => {
    const weekDays = [
      {
        id: 1,
        label: 'Lunes',
        executions: [],
      },
      {
        id: 2,
        label: 'Martes',
        executions: [],
      },
      {
        id: 3,
        label: 'Miércoles',
        executions: [],
      },
      {
        id: 4,
        label: 'Jueves',
        executions: [],
      },
      {
        id: 5,
        label: 'Viernes',
        executions: [],
      },
      {
        id: 6,
        label: 'Sábado',
        executions: [],
      },
      {
        id: 0,
        label: 'Domingo',
        executions: [],
      },
    ];

    return data.map(item => {
      const calendar = clone(weekDays);
      let scheduled = 0;
      let worked = 0;
      let delay = 0;

      item.orders_cleaner.forEach(oc => oc.executions.forEach(exec => {
        let scheduledStart = '--';
        let scheduledEnd = '--';
        let workedStart = '--';
        let workedEnd = '--';
        let delay = 0;

        const timeStartsAt = moment(oc.order.time_starts_at, 'HH:mm:ss');
        const timeEndsAt = moment(oc.order.time_ends_at, 'HH:mm:ss');

        // Verificar el día de la semana
        const weekDay = moment(exec.date).get('weekday');

        // Establece el horario
        scheduledStart = timeStartsAt.format('h:mma');
        scheduledEnd = timeEndsAt.format('h:mma');

        // Establece la fecha de inicio y final
        if (exec.started_at) workedStart = moment(exec.started_at).format('h:mma');
        if (exec.ended_at) workedEnd = moment(exec.ended_at).format('h:mma');

        // Establece el tiempo de retraso
        if (exec.started_at) {
          // Calcula el tiempo de retraso verificando los minutos de diferencia al horario establecido
          const startTime = moment(String(exec.started_at).split(' ')[1], 'HH:mm:ss');
          const startMinutesDiff = timeStartsAt.diff(startTime, 'minutes');

          // Comenzó después, tiempo de retraso
          if (startMinutesDiff < 0) delay += Math.abs(startMinutesDiff);
        }

        // Calcula las horas programadas
        scheduled += timeEndsAt.diff(timeStartsAt, 'minutes');

        // Calcula el total de horas trabajadas
        worked += exec.total_time;

        // Obtiene el día de la semana donde se guardará el dato
        const slot = calendar.findIndex(x => x.id === weekDay);
        calendar[slot].executions.push({ delay, scheduledStart, scheduledEnd, workedStart, workedEnd });
      }));

      return {
        id: item.id,
        person: item.person,
        calendar,
        scheduled,
        worked,
        delay,
      };
    });
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    setData([]);

    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    // fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
  }
}

export default Calculation;