import { fetchWrapper, objectToQueryParams } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'admin/orders';

export async function create(form) {
  return await fetchWrapper.post(baseUrl, form);
}

export async function findAll(filters) {
  const url = `${baseUrl}?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function findHistory(filters) {
  const url = `${baseUrl}/executions/history?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function update(form) {
  return await fetchWrapper.patch(baseUrl, form);
}

export async function destroy(userId) {
  return await fetchWrapper.delete(baseUrl, { id: userId });
}

export async function download(filters) {
  const url = `${baseUrl}/executions/history/report?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}