import { useContext } from 'react';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter } from 'react-router-dom';
import { AuthContext } from '../context';
import Login from '../screens/auth/login';
import Logout from '../screens/auth/logout';
import RecoverPassword from '../screens/auth/recover';
import Orders from '../screens/home/orders';
import Stores from '../screens/stores/stores';
import Cleaners from '../screens/cleaners/cleaners';
import History from '../screens/history/history';
import Monitor from '../screens/monitor/monitor';
import Payroll from '../screens/payroll/payroll';
import PayrollCalculation from '../screens/payroll/calculation';
import PayrollPayments from '../screens/payroll/payments';
import Reports from '../screens/reports/reports';
import ReportWork from '../screens/reports/work-report';
import ReportPayment from '../screens/reports/payment-report';
import Layout from './layout';

const Router = () => {
  const { isLogged } = useContext(AuthContext);

  const authRouter = createBrowserRouter([
    {
      index: true,
      path: '/login',
      element: <Login />,
    },
    {
      index: true,
      path: '/recover',
      element: <RecoverPassword />,
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    }
  ], { basename: process.env.REACT_APP_BASENAME });

  const adminRouter = createBrowserRouter([
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      index: true,
      path: '*',
      Component: AdminRoutes,
    },
  ], { basename: process.env.REACT_APP_BASENAME });

  return (
    <RouterProvider router={isLogged ? adminRouter : authRouter} />
  )
}

const AdminRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="/" element={<Orders />} />
      <Route path="/users" element={<Cleaners />} />
      <Route path="/stores" element={<Stores />} />
      <Route path="/history" element={<History />} />
      <Route path="/monitor" element={<Monitor />} />
      <Route path="/payroll" element={<Payroll />} />
      <Route path="/payroll/calculation" element={<PayrollCalculation />} />
      <Route path="/payroll/payments" element={<PayrollPayments />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/work" element={<ReportWork />} />
      <Route path="/reports/payments" element={<ReportPayment />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
)

export default Router;