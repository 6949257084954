import { fetchWrapper, objectToQueryParams } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'cleaners';

export async function findAll(filters) {
  const url = `${baseUrl}?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function findAllForSelect(filters) {
  const url = `${baseUrl}/for-select?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function update(form) {
  return await fetchWrapper.patch(baseUrl, form);
}

export async function create(form) {
  const url = `${baseUrl}/create`;
  return await fetchWrapper.post(url, form);
}

export async function updateProfile(form) {
  const url = `${baseUrl}/profile`;
  return await fetchWrapper.patch(url, form);
}

export async function destroy(userId) {
  const url = `${baseUrl}/delete`;
  return await fetchWrapper.post(url, { id: userId });
}

export async function download(filters) {
  const url = `${baseUrl}/report?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}